export function mappingMetricUnitName(metricName: string): string {
  switch (metricName) {
    // Misc
    case 'percent':
      return '%';
    case 'humidity':
      return '%H';
    case 'decibel':
      return 'dB';
    case 'candela':
      return 'cd';
    case 'pixel':
      return 'px';
    // Acceleration
    case 'accMS2':
      return 'm/s²';
    case 'accFS2':
      return 'ft/s²';
    case 'accG':
      return 'G';
    // Angle
    case 'degree':
      return '°';
    case 'radian':
      return 'rad';
    case 'grad':
      return 'grad';
    case 'arcmin':
      return "'";
    case 'arcsec':
      return '"';
    // Area
    case 'areaM2':
      return 'm²';
    case 'areaF2':
      return 'ft²';
    case 'areaMI2':
      return 'mi²';
    case 'acres':
      return 'ac';
    case 'hectares':
      return 'ha';
    // Computation
    case 'flops':
      return 'FLOP/s';
    case 'mflops':
      return 'MFLOP/s';
    case 'gflops':
      return 'GFLOP/s';
    case 'tflops':
      return 'TFLOP/s';
    case 'pflops':
      return 'PFLOP/s';
    case 'eflops':
      return 'EFLOP/s';
    case 'zflops':
      return 'ZFLOP/s';
    case 'yflops':
      return 'YFLOP/s';
    // Concentration
    case 'conngm3':
      return 'ng/m³';
    case 'conngNm3':
      return 'ng/Nm³';
    case 'conμgm3':
      return 'μg/m³';
    case 'conμgNm3':
      return 'μg/Nm³';
    case 'conmgm3':
      return 'mg/m³';
    case 'conmgNm3':
      return 'mg/Nm³';
    case 'congm3':
      return 'g/m³';
    case 'congNm3':
      return 'g/Nm³';
    case 'conmgdL':
      return 'mg/dL';
    case 'conmmolL':
      return 'mmol/L';
    // Currency
    case 'currencyUSD':
      return '$';
    case 'currencyGBP':
      return '£';
    case 'currencyEUR':
      return '€';
    case 'currencyJPY':
      return '¥';
    case 'currencyRUB':
      return '₽';
    case 'currencyUAH':
      return '₴';
    case 'currencyBRL':
      return 'R$';
    case 'currencyDKK':
      return 'kr';
    case 'currencyISK':
      return 'kr';
    case 'currencyNOK':
      return 'kr';
    case 'currencySEK':
      return 'kr';
    case 'currencyCZK':
      return 'Kč';
    case 'currencyCHF':
      return 'CHF';
    case 'currencyPLN':
      return 'zł';
    case 'currencyBTC':
      return '฿';
    case 'currencymBTC':
      return '฿';
    case 'currencyμBTC':
      return '฿';
    case 'currencyZAR':
      return 'R';
    case 'currencyINR':
      return '₹';
    case 'currencyKRW':
      return '₩';
    case 'currencyIDR':
      return 'Rp';
    case 'currencyPHP':
      return '₱';
    case 'currencyVND':
      return '₫';
    case 'currencyTRY':
      return '₺';
    case 'currencyMYR':
      return 'RM';
    case 'currencyXPF':
      return 'XPF';
    case 'currencyBGN':
      return 'лв';
    case 'currencyPYG':
      return '₲';
    case 'currencyUYU':
      return '$U';
    // Data
    case 'bytes':
      return 'B';
    case 'kilobytes':
      return 'KB';
    case 'megabytes':
      return 'MB';
    case 'gigabytes':
      return 'GB';
    case 'terabytes':
      return 'TB';
    case 'petabytes':
      return 'PB';
    case 'exabytes':
      return 'EB';
    case 'zettabytes':
      return 'ZB';
    case 'yottabytes':
      return 'YB';
    case 'bits':
      return 'b';
    case 'kilobits':
      return 'Kb';
    case 'megabits':
      return 'Mb';
    case 'gigabits':
      return 'Gb';
    case 'terabits':
      return 'Tb';
    case 'petabits':
      return 'Pb';
    case 'exabits':
      return 'Eb';
    case 'zettabits':
      return 'Zb';
    case 'yottabits':
      return 'Yb';
    // Data Rate
    case 'pps':
      return 'pps';
    case 'binBps':
      return 'B/s (IEC)';
    case 'Bps':
      return 'B/s';
    case 'binbps':
      return 'bps (IEC)';
    case 'bps':
      return 'bps';
    case 'KiBs':
      return 'KiB/s';
    case 'Kibits':
      return 'Kibits/s';
    case 'KBs':
      return 'KB/s';
    case 'Kbits':
      return 'Kbits/s';
    case 'MiBs':
      return 'MiB/s';
    case 'Mibits':
      return 'Mibits/s';
    case 'MBs':
      return 'MB/s';
    case 'Mbits':
      return 'Mbits/s';
    case 'GiBs':
      return 'GiB/s';
    case 'Gibits':
      return 'Gibits/s';
    case 'GBs':
      return 'GB/s';
    case 'Gbits':
      return 'Gbits/s';
    case 'TiBs':
      return 'TiB/s';
    case 'Tibits':
      return 'Tibits/s';
    case 'TBs':
      return 'TB/s';
    case 'Tbits':
      return 'Tbits/s';
    case 'PiBs':
      return 'PiB/s';
    case 'Pibits':
      return 'Pibits/s';
    case 'PBs':
      return 'PB/s';
    case 'Pbits':
      return 'Pbits/s';
    // Date & time
    // Energy
    case 'watt':
      return 'W';
    case 'kwatt':
      return 'kW';
    case 'megwatt':
      return 'MW';
    case 'gwatt':
      return 'GW';
    case 'mwatt':
      return 'mW';
    case 'Wm2':
      return 'W/m²';
    case 'voltamp':
      return 'VA';
    case 'kvoltamp':
      return 'kVA';
    case 'voltampreact':
      return 'VAr';
    case 'kvoltampreact':
      return 'kVAr';
    case 'watth':
      return 'Wh';
    case 'watthperkg':
      return 'Wh/kg';
    case 'kwatth':
      return 'kWh';
    case 'kwattm':
      return 'kWm';
    case 'mwatth':
      return 'MWh';
    case 'amph':
      return 'Ah';
    case 'kamph':
      return 'kAh';
    case 'mamph':
      return 'mAh';
    case 'joule':
      return 'J';
    case 'ev':
      return 'eV';
    case 'amp':
      return 'A';
    case 'kamp':
      return 'kA';
    case 'mamp':
      return 'mA';
    case 'volt':
      return 'V';
    case 'kvolt':
      return 'kV';
    case 'mvolt':
      return 'mV';
    case 'dBm':
      return 'dBm';
    case 'mohm':
      return 'mΩ';
    case 'ohm':
      return 'Ω';
    case 'kohm':
      return 'kΩ';
    case 'Mohm':
      return 'MΩ';
    case 'farad':
      return 'F';
    case 'µfarad':
      return 'µF';
    case 'nfarad':
      return 'nF';
    case 'pfarad':
      return 'pF';
    case 'ffarad':
      return 'fF';
    case 'henry':
      return 'H';
    case 'mhenry':
      return 'mH';
    case 'µhenry':
      return 'µH';
    case 'lumens':
      return 'lm';
    // Flow
    case 'flowgpm':
      return 'gp/m';
    case 'flowcms':
      return 'cm/s';
    case 'flowcfs':
      return 'cf/s';
    case 'flowcfm':
      return 'cf/m';
    case 'litreh':
      return 'L/h';
    case 'flowlpm':
      return 'L/m';
    case 'flowmlpm':
      return 'mL/m';
    case 'lux':
      return 'lx';
    // Force
    case 'forceNm':
      return 'N/m';
    case 'forcekNm':
      return 'kN/m';
    case 'forceN':
      return 'N';
    case 'forcekN':
      return 'kN';
    // Hash rate
    case 'Hs':
      return 'H/s';
    case 'KHs':
      return 'KH/s';
    case 'MHs':
      return 'MH/s';
    case 'GHs':
      return 'GH/s';
    case 'THs':
      return 'TH/s';
    case 'PHs':
      return 'PH/s';
    case 'EHs':
      return 'EH/s';
    // Mass
    case 'massmg':
      return 'mg';
    case 'massg':
      return 'g';
    case 'masslb':
      return 'lb';
    case 'masskg':
      return 'kg';
    case 'masst':
      return 't';
    // Length
    case 'lengthmm':
      return 'mm';
    case 'lengthin':
      return 'in';
    case 'lengthft':
      return 'ft';
    case 'lengthm':
      return 'm';
    case 'lengthkm':
      return 'km';
    case 'lengthmi':
      return 'mi';
    // Pressure
    case 'pressurembar':
      return 'mbar';
    case 'pressurebar':
      return 'bar';
    case 'pressurekbar':
      return 'kbar';
    case 'pressurepa':
      return 'Pa';
    case 'pressurehpa':
      return 'hPa';
    case 'pressurekpa':
      return 'kPa';
    case 'pressurehg':
      return 'Hg';
    case 'pressurepsi':
      return 'psi';
    // Radiation
    case 'radbq':
      return 'Bq';
    case 'radci':
      return 'Ci';
    case 'radgy':
      return 'Gy';
    case 'radrad':
      return 'rad';
    case 'radsv':
      return 'Sv';
    case 'radmsv':
      return 'mSv';
    case 'radusv':
      return 'µSv';
    case 'radrem':
      return 'rem';
    case 'radexpckg':
      return 'C/kg';
    case 'radr':
      return 'R';
    case 'radsvh':
      return 'Sv/h';
    case 'radmsvh':
      return 'mSv/h';
    case 'radusvh':
      return 'µSv/h';
    // Rotation speed
    case 'rotrpm':
      return 'rpm';
    case 'rothz':
      return 'Hz';
    case 'rotkhz':
      return 'kHz';
    case 'rotmhz':
      return 'MHz';
    case 'rotghz':
      return 'GHz';
    case 'rotrads':
      return 'rad/s';
    case 'rotdegs':
      return '°/s';
    // Temperature
    case 'celsius':
      return '°C';
    case 'fahrenheit':
      return '°F';
    case 'kelvin':
      return 'K';
    // Time
    case 'hertz':
      return 'Hz';
    case 'ns':
      return 'ns';
    case 'µs':
      return 'µs';
    case 'ms':
      return 'ms';
    case 's':
      return 's';
    case 'm':
      return 'm';
    case 'h':
      return 'h';
    case 'd':
      return 'd';
    case 'dtdurationms':
      return 'ms';
    case 'dtdurations':
      return 's';
    case 'dthms':
      return 'hh:mm:ss';
    case 'dtdhms':
      return 'd hh:mm:ss';
    // Throughput
    case 'cps':
      return 'cps';
    case 'ops':
      return 'ops';
    case 'reqps':
      return 'req/s';
    case 'rps':
      return 'read/s';
    case 'wps':
      return 'write/s';
    case 'iops':
      return 'io/s';
    case 'eps':
      return 'event/s';
    case 'mps':
      return 'msg/s';
    case 'recps':
      return 'rec/s';
    case 'rowsps':
      return 'rows/s';
    case 'cpm':
      return 'count/m';
    case 'opm':
      return 'op/m';
    case 'reqpm':
      return 'req/m';
    case 'rpm':
      return 'reads/m';
    case 'wpm':
      return 'write/m';
    case 'epm':
      return 'event/m';
    case 'mpm':
      return 'msg/m';
    case 'recpm':
      return 'rec/m';
    case 'rowspm':
      return 'rows/m';
    // Velocity
    case 'velocityms':
      return 'm/s';
    case 'velocitykmh':
      return 'km/h';
    case 'velocitymph':
      return 'mph';
    case 'velocityknot':
      return 'kn';
    // Volume
    case 'mlitre':
      return 'mL';
    case 'litre':
      return 'L';
    case 'm3':
      return 'm³';
    case 'Nm3':
      return 'Nm³';
    case 'dm3':
      return 'dm³';
    case 'gallons':
      return 'gal';
    // Boolean
    case 'bool':
      return 'True/False';
    case 'bool_yes_no':
      return 'Yes/No';
    case 'bool_on_off':
      return 'On/Off';
    default:
      return metricName;
  }
}
